import axiosClient from '../../../../api/axiosClient.js';
import { fetchPageData } from '../../../../api/utils.js';

async function getGames() {
  return fetchPageData(`/api/games/games/`);
}

export const gamesQuery = () => {
  return {
    queryKey: ['games'],
    queryFn: async () => getGames(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getSessions() {
  return fetchPageData(`/api/games/sessions/`);
}

export const sessionsQuery = () => {
  return {
    queryKey: ['sessionsInstructorAssignments'],
    queryFn: async () => getSessions(),
    refetchOnWindowFocus: false
  };
};

async function getCourses() {
  return fetchPageData(`/api/users/courses/`);
}

export const coursesQuery = () => {
  return {
    queryKey: ['courses'],
    queryFn: async () => getCourses(),
    refetchOnWindowFocus: false
  };
};

async function getGamesItems() {
  return fetchPageData(`/api/games/items/`);
}

export const gamesItemsQuery = () => {
  return {
    queryKey: ['gamesItems'],
    queryFn: async () => getGamesItems(),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};

async function getScenarios() {
  return fetchPageData(`/api/games/scenarios/`);
}

export const scenariosQuery = () => {
  return {
    queryKey: ['scenarios'],
    queryFn: async () => getScenarios(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: false
  };
};

export async function createScenario({
  name,
  courses
}) {
  const { data } = await axiosClient.post(`api/games/scenarios/`, {
    name,
    is_published: true,
    courses
  });

  return data;
}

export async function editScenario({
  id,
  name
}) {
  const { data } = await axiosClient.patch(`api/games/scenarios/${id}/`, {
    name
  });

  return data;
}

export async function createGame({
  name,
  description,
  startTime,
  endTime,
  gameType,
  scenarioId,
  courseId
}) {
  const gameTypeMapping = {
    'Account Classification': 'ACC',
    'The Principles': 'PR',
    'Normal Balance': 'NOR',
    'Balanced Equation': 'BE',
    'Accounting Cycle': 'AC',
  };
  
  const gameTypeValue = gameTypeMapping[gameType] || '';
  const { data } = await axiosClient.post(`api/games/games/create/`, {
    name,
    description,
    start_time: startTime,
    end_time: endTime,
    points: 100,
    is_active: true,
    game_type: gameTypeValue,
    scenario: scenarioId,
    course: courseId
  });

  return data;
}

export async function createScenarioLevels({
  name,
  levelNumber,
  scenarios,
  description,
  design
}) {
  const { data } = await axiosClient.post(`api/games/scenario-levels/`, {
    name,
    falling_speed: 2,
    level: levelNumber,
    show_score: true,
    show_value: true,
    scenarios,
    description,
    design
  });

  return data;
}

export async function editScenarioLevel({
  scenarioLevelId,
  design
}) {
  const { data } = await axiosClient.patch(`api/games/scenario-levels/${scenarioLevelId}/`, {
    design
  });

  return data;
}

export async function createItemQuestions({
  gameType,
  order,
  accountItemId,
  scenarioLevelId
}) {
  const lastSlugPart = gameType === 'classification' 
    ? 'item-questions'
    : gameType === 'principles'
      ? 'principles-questions'
      : 'questions';
  const { data } = await axiosClient.post(`api/${gameType}/${lastSlugPart}/`, {
    order,
    ...(gameType === 'equation' || gameType === 'principles' ? { transaction: accountItemId } : { account_item: accountItemId }),
    scenario_level: scenarioLevelId
  });

  return data;
}

export async function deleteItemQuestions({gameType, questionId}) {
  const lastSlugPart = gameType === 'classification' 
    ? 'item-questions'
    : gameType === 'principles'
      ? 'principles-questions'
      : 'questions';
  await axiosClient.delete(`api/${gameType}/${lastSlugPart}/${questionId}/`);
}

export async function createItemOptions({
  gameType,
  order,
  accountItemId,
  scenarioLevelId
}) {
  const lastSlugPart = gameType === 'classification' ? 'item-options' : 'options';
  const { data } = await axiosClient.post(`api/${gameType}/${lastSlugPart}/`, {
    order,
    account_item: accountItemId,
    scenario_level: scenarioLevelId
  });

  return data;
}

export async function deleteItemOptions({gameType = 'equation', optionId}) {
  const lastSlugPart = gameType === 'classification' 
    ? 'item-options'
    : 'options';
  await axiosClient.delete(`api/${gameType}/${lastSlugPart}/${optionId}/`);
}

export async function editGame({
  id,
  name,
  description,
  startTime,
  endTime
}) {
  const { data } = await axiosClient.patch(`api/games/games/update/${id}/`, {
    name,
    description,
    start_time: startTime,
    end_time: endTime,
  });

  return data;
}

async function getScenarioLevels({scenarioId}) {  
  return scenarioId ? fetchPageData(`/api/games/scenario-levels/?scenarios=${scenarioId}`) : [];
}

export const scenarioLevelsQuery = ({scenarioId}) => {
  return {
    queryKey: [`gameScenarioLevels`, scenarioId],
    queryFn: async () => getScenarioLevels({scenarioId}),
    refetchOnWindowFocus: false,
    cacheTime: 0
  };
};


export async function deleteGame({gameId}) {
  await axiosClient.delete(`api/games/games/${gameId}/`);
}

export async function deleteScenarioLevel({scenarioLevelId}) {
  await axiosClient.delete(`api/games/scenario-levels/${scenarioLevelId}/`);
}

export async function scenarioAddCourse({scenarioId, courseId}) {
  const { data } = await axiosClient.put(`api/games/scenarios/${scenarioId}/add-course/`, { course_id: courseId });

  return data;
}