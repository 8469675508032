import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Layout from '../UI/introGameLayout/layout';

import IntroLevel1 from './videos/intro-level-1.mp4';
import IntroDebitsCredits from './videos/intro-debits-credits.mp4';

import './styles.css';
import Video from '../UI/introGameLayout/video';

import { gamesQuery, scenarioLevelsQuery } from './api';

import useAuth from '../../auth/useAuth';

export default function BalancedEquationIntro() {
  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const { getCurrentBalancedGameId } = useAuth();

  const gameId = getCurrentBalancedGameId();

  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Balanced Equation') && game.id === gameId);

      if(game && game.scenario) {
        setScenarioId(game.scenario);
      }
    }
  }, [games, gameId]);

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery({scenarioId})) || {};
  const scenarioLevels = scenarioLevelsResponse.data || null;

  const handleContinueButtonClick = () => {
    let redirectUrl;

    if (levelNumber === 1) {
      const firstScenarioLevel = scenarioLevels?.find(scenarioLevel => scenarioLevel.level === 1);
      if(firstScenarioLevel?.design.includes('2')) {
        redirectUrl = '/balanced-equation/debits-credits/intro';
      } else {
        redirectUrl = `/balanced-equation/level/1`;
      }
    } else {
      const sortedScenarioLevels = [...scenarioLevels].sort((a, b) => a.level - b.level);

      const firstLevelWithDesign2 = sortedScenarioLevels.find(scenarioLevel => 
        scenarioLevel.design.includes('2')
      );
      redirectUrl = `/balanced-equation/level/${firstLevelWithDesign2?.level}`;
    }

    navigate(redirectUrl);
  }

  const introVideo = levelNumber && levelNumber=== 1 ? IntroLevel1 : IntroDebitsCredits;

  return(
    <Layout
      isHeaderVisible={true}
      isContentVertical={true}
      backgroundColor={'#3A507B'}
    >
      <div className="intro-video-wrapper">
        <Video videoSrc={introVideo} />
        {
          scenarioLevels?.length > 0 &&
            <button className="intro-button" onClick={handleContinueButtonClick}>Continue</button>
        }
      </div>
    </Layout>
  );
}
