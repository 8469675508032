import TransactionRecordsHeader from './transactionRecordsHeader';
import TransactionRecordsBody from './transactionRecordsBody';
import TransactionRecordsFooter from './transactionRecordsFooter';

import './styles.css';

export default function TransactionRecords({
  transactionText,
  value,
  topOptions,
  changeTypeOptions,
  coreTypeOptions,
  transactionTypeOptions,
  onChangeItemDrop,
  onCoreItemDrop,
  onDropAreaChange,
  isSubmitButtonShown,
  onSubmitButtonClick,
  onSubmitButtonMouseEnter,
  onSubmitButtonMouseLeave,
  onNextButtonClick,
  isSubmitButtonLoading,
  isButtonDisabled,
  resetKey,
  incorrectDecisions,
  isTopHintShown,
  topHintContent,
  onTopHintButtonClick,
  isChangeHintShown,
  isCoreHintShown,
  isTransactionHintShown,
  changeHintContent,
  onChangeHintButtonClick,
  onCoreHintButtonClick,
  onTransactionHintButtonClick,
  isFirstDropHintShown,
  onFirstDropHintButtonClick,
  isSubmitHintShown,
  submitHintContent,
  onSubmitHintButtonClick,
  isItemsDisabled,
  level,
  selectedDesignTypes
}) {
  return (
    <div className="transaction-records-wrapper">
      <TransactionRecordsHeader
        transactionText={transactionText}
        ishintShown={isTopHintShown}
        hintContent={topHintContent}
        onHintButtonClick={onTopHintButtonClick}
      />
      <TransactionRecordsBody
        options={topOptions}
        value={value}
        onChangeItemDrop={onChangeItemDrop}
        onCoreItemDrop={onCoreItemDrop}
        onDropAreaChange={onDropAreaChange}
        resetKey={resetKey}
        incorrectDecisions={incorrectDecisions}
        isItemsDisabled={isItemsDisabled}
        level={level}
        selectedDesignTypes={selectedDesignTypes}
      />
      <TransactionRecordsFooter
        changeTypeOptions={changeTypeOptions}
        coreTypeOptions={coreTypeOptions}
        transactionTypeOptions={transactionTypeOptions}
        isButtonDisabled={isButtonDisabled}
        isSubmitButtonShown={isSubmitButtonShown}
        onSubmitButtonClick={onSubmitButtonClick}
        onSubmitButtonMouseEnter={onSubmitButtonMouseEnter}
        onSubmitButtonMouseLeave={onSubmitButtonMouseLeave}
        onNextButtonClick={onNextButtonClick}
        isSubmitButtonLoading={isSubmitButtonLoading}
        isTopHintShown={isTopHintShown}
        isChangeHintShown={isChangeHintShown}
        isCoreHintShown={isCoreHintShown}
        isTransactionHintShown={isTransactionHintShown}
        changeHintContent={changeHintContent}
        onChangeHintButtonClick={onChangeHintButtonClick}
        onCoreHintButtonClick={onCoreHintButtonClick}
        onTransactionHintButtonClick={onTransactionHintButtonClick}
        isFirstDropHintShown={isFirstDropHintShown}
        onFirstDropHintButtonClick={onFirstDropHintButtonClick}
        isSubmitHintShown={isSubmitHintShown}
        submitHintContent={submitHintContent}
        onSubmitHintButtonClick={onSubmitHintButtonClick}
        isItemsDisabled={isItemsDisabled}
      />
    </div>
  );
}
