import { useState, useEffect } from 'react';
import {
  accountColumnTitles,
  balancedEquationAccountsTexting,
  thePrinciplesAccountsTexting,
  decisionDesignTypes
} from './data';

import InstructorAccountItemCheckbox from './accountCheckbox';
import InstructorAccountItemRadioButton from './accountRadioButton';
import Alert from '../../../alert';

import './styles.css';
import Hint from '../../../hint';

export default function InstructorLevelItemsModal({
  scenarioLevelNumber,
  onCloseButtonClick,
  onSaveButtonClick,
  isButtonLoading,
  gameType,
  filterItems,
  editingScenarioLevelPreCheckedItems = [],
  designTypes
}) {
  const getVisibleItemsByFilters = (selectedParent, selectedFilter) => {
    return filterItems[selectedParent][selectedFilter];
  };

  const defaultParent = Object.keys(filterItems)[0] || '';
  const defaultFilter = defaultParent ? Object.keys(filterItems[defaultParent])[0] : '';

  const [selectedFilterParent, setSelectedFilterParent] = useState(defaultParent);
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [accountItemsVisible, setAccountItemsVisible] = useState([]);
  const [accountItemsChecked, setAccountItemsChecked] = useState([]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isHintShown, setIsHintShown] = useState(false);
  
  const [designTypesChecked, setDesignTypesChecked] = useState(() => {
    if (designTypes?.length) return designTypes;
    if (scenarioLevelNumber === 1) return ["1"];
    if (scenarioLevelNumber === 2) return ["2"];
    return ["1", "2", "3"];
  });

  useEffect(() => {
    if (Object.keys(filterItems).length > 0) {
      const defaultParent = Object.keys(filterItems)[0];
      const defaultFilter = Object.keys(filterItems[defaultParent])[0] || '';
  
      setSelectedFilterParent(defaultParent);
      setSelectedFilter(defaultFilter);
  
      if (defaultParent && defaultFilter) {
        const visibleItems = getVisibleItemsByFilters(defaultParent, defaultFilter);
        setAccountItemsVisible(visibleItems);
  
        let preCheckedItems = [];

        if(!editingScenarioLevelPreCheckedItems.length) {
          if (gameType === 'Balanced Equation') {
            const preCheckedOrders =
              scenarioLevelNumber === 1
                ? [1, 2, 3]
                : scenarioLevelNumber === 2
                ? [4, 5, 6]
                : scenarioLevelNumber === 3
                ? [7, 8, 9]
                : [];
    
            preCheckedItems = Object.values(filterItems)
              .flatMap(childFilters => Object.values(childFilters).flat())
              .filter(item => preCheckedOrders.includes(item.order));
          } else if (gameType === 'The Principles') {
            const preCheckedOrders =
              scenarioLevelNumber === 1
                ? [1, 2, 3, 4, 5, 6]
                : scenarioLevelNumber === 2
                ? [7, 8, 9, 10, 11, 12]
                : scenarioLevelNumber === 3
                ? [1, 2, 13, 14, 15, 16, 17, 18]
                : [1, 2, 12, 3, 4, 7, 9, 11, 6];
    
            preCheckedItems = Object.values(filterItems)
              .flatMap(childFilters => Object.values(childFilters).flat())
              .filter(item => preCheckedOrders.includes(item.order));
          } else {
            const corporateOrders = [
              1, 2, 4, 6, 7, 8, 12, 13, 14, 15, 22, 23, 24, 25, 28, 30, 31, 32, 33, 34, 36, 42,
            ];
            const corporateItems = filterItems['Entity Type']?.Corporate || [];
            preCheckedItems = corporateItems.filter(item => corporateOrders.includes(item.order));
    
            const noncorporateOrders = [
              1, 2, 3, 6, 8, 11, 12, 15, 23, 24, 25, 28, 29, 30, 31, 33, 36,
            ];
            const noncorporateItems = filterItems['Entity Type']?.Noncorporate || [];
            preCheckedItems = [
              ...preCheckedItems,
              ...noncorporateItems.filter(item => noncorporateOrders.includes(item.order)),
            ];
    
            const specialAccountsItems = Object.values(filterItems['Special Accounts'] || {}).flatMap(
              childFilters => childFilters
            );
            preCheckedItems = [...preCheckedItems, ...specialAccountsItems];
          }
        } else {
          preCheckedItems = Object.values(filterItems)
            .flatMap(childFilters => Object.values(childFilters).flat())
            .filter(item => editingScenarioLevelPreCheckedItems.some(preCheckedItem => preCheckedItem.text === item.text));
        }
    
        setAccountItemsChecked(preCheckedItems);
      }
    }
  }, [filterItems, scenarioLevelNumber]);

  const handleFilterChange = (name, parentName) => {
    let parentFilter = null;
  
    for (const [parentKey, childItems] of Object.entries(filterItems)) {
      if (parentKey === name || (Object.keys(childItems).includes(name) && parentKey === parentName)) {
        parentFilter = parentKey;
        break;
      }
    }
  
    const isParent = parentFilter === name;
    const newSelectedFilter = isParent
      ? Object.keys(filterItems[parentFilter])[0] || name
      : name;
  
    setSelectedFilter(newSelectedFilter);
    setSelectedFilterParent(parentFilter);
  
    setAccountItemsVisible(filterItems[parentFilter]?.[newSelectedFilter] || []);
  };
  

  const handleAccountItemCheck = (name, isChecked) => {
    let updatedCheckedItems;

    if (isChecked) {
      const item = accountItemsVisible.find(item => item.text === name);
      if (item) {
        updatedCheckedItems = [...accountItemsChecked, item];
      }
    } else {
      updatedCheckedItems = accountItemsChecked.filter(item => item.text !== name);
    }

    setAccountItemsChecked(updatedCheckedItems);
  };

  const handleAccountItemsSaved = () => {
    onCloseButtonClick();
  };

  const handleSaveButtonClick = () => {
    const accountItemsVisibleIds = accountItemsVisible.map(item => item.id);
    const accountItemsCheckedToSave = editingScenarioLevelPreCheckedItems.length
      ? accountItemsChecked.filter((item, index, self) => index === self.findIndex(other => other.text === item.text))
      : accountItemsChecked.filter(item => accountItemsVisibleIds.includes(item.id));

    if (accountItemsCheckedToSave.length > 20) {
      setIsAlertVisible(true);
    } else {
      onSaveButtonClick(accountItemsCheckedToSave, selectedFilter, selectedFilterParent, handleAccountItemsSaved, gameType, designTypesChecked);
    }
  };

  const handleConfirmAlertButtonClick = () => {
    setIsAlertVisible(false);
  };

  const selectAccountsHeadingPart = gameType === 'Balanced Equation' || gameType === 'The Principles'
    ? 'Selecting Transactions'
    : 'Select Accounts';
  
  const selectAccountsDescription =
    gameType === 'Balanced Equation'
      ? balancedEquationAccountsTexting[scenarioLevelNumber - 1] || balancedEquationAccountsTexting[2]
      : gameType === 'The Principles'
        ? thePrinciplesAccountsTexting
        : 'Max selection: 20 Accounts';

  const handleDesignTypeItemCheck = (index, isChecked) => {
    let updatedCheckedItems = [...designTypesChecked];
  
    if (isChecked) {
      updatedCheckedItems.push(String(index));
    } else {
      updatedCheckedItems = updatedCheckedItems.filter(item => item !== String(index));
    }
  
    if (updatedCheckedItems.includes("3") && !(updatedCheckedItems.includes("1") || updatedCheckedItems.includes("2"))) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  
    setDesignTypesChecked(updatedCheckedItems);
  };

  const handleButtonMouseEnter = () => {
    setIsHintShown(true);
  }

  const handleButtonMouseLeave = () => {
    setIsHintShown(false);
  }

  return (
    <div className={`modal-overlay`}>
      <div className="modal instructor-level-items-modal">
        <h3>Level {scenarioLevelNumber} | {selectAccountsHeadingPart}</h3>
        <button className="close-button" onClick={onCloseButtonClick}></button>

        <p>{selectAccountsDescription}</p>

        <div className="instructor-level-items-filter-container">
          <div className="instructor-level-items-filter-column">
            <h4>Select Filter</h4>
            {Object.entries(filterItems).map(([parentKey, childItems], index) => (
              <div key={`filter-${index}`}>
                <InstructorAccountItemRadioButton
                  id={`${parentKey}-${index}`}
                  isChecked={selectedFilterParent === parentKey}
                  label={parentKey}
                  onCheck={() => handleFilterChange(parentKey)}
                />
                <div className="account-item-children">
                  {Object.keys(childItems).map((childKey, subIndex) => (
                    <InstructorAccountItemRadioButton
                      key={`child-${index}-${subIndex}`}
                      id={`${childKey}-${index}-${subIndex}`}
                      isChecked={selectedFilterParent === parentKey && selectedFilter === childKey}
                      label={childKey}
                      onCheck={() => handleFilterChange(childKey, parentKey)}
                    />
                  ))}
                </div>
              </div>
            ))}
            {
              (gameType === 'Balanced Equation' && Object.entries(filterItems).length > 0) &&
                <div className="be-decision-type-container">
                  <h5>Decision Type</h5>
                  {
                    decisionDesignTypes.map((decisionDesignType, index) => (
                      <InstructorAccountItemCheckbox
                        key={`decision-design-${index}`}
                        isChecked={designTypesChecked.some(designTypeChecked => designTypeChecked === String(index + 1))}
                        label={decisionDesignType}
                        onCheck={(event) =>
                          handleDesignTypeItemCheck(index + 1, event.target.checked)
                        }
                      />
                    ))
                  }
                </div>
            }
          </div>

          {accountColumnTitles[gameType].map((columnTitle, index) => {
            const relatedItems = accountItemsVisible?.filter((item) =>
              item.column === columnTitle
            );

            return (
              <div className="instructor-level-items-filter-column" key={`filter-column-${index}`}>
                <h4>{columnTitle}</h4>

                {relatedItems?.map((item, itemIndex) => (
                  <InstructorAccountItemCheckbox
                    key={`item-${index}-${itemIndex}`}
                    isChecked={accountItemsChecked.some(checkedItem => checkedItem.id === item.id)}
                    label={item.text}
                    onCheck={(event) =>
                      handleAccountItemCheck(item.text, event.target.checked)
                    }
                  />
                ))}
              </div>
            );
          })}
        </div>

        <div className="save-account-items-selection-container">
          <button
            className={`button-blue save-account-items-selection button-save-assignment ${isButtonLoading ? 'loading' : ''} ${isButtonDisabled ? 'disabled' : ''}`}
            onClick={!isButtonDisabled ? handleSaveButtonClick : null}
            onMouseEnter={isButtonDisabled ? handleButtonMouseEnter : null}
            onMouseLeave={isButtonDisabled ? handleButtonMouseLeave : null}
          >
            {!isButtonLoading && 'Save'}
          </button>
          {
            isHintShown &&
              <Hint 
                content={<span><b>Incomplete Selection.</b> Selecting an account alone isn't enough to add a level! You also need to choose an 'Increase or Decrease' and/or a 'Debits & Credits' decision.</span>}
                side="right"
              />
          }
        </div>
      </div>

      {
        isAlertVisible &&
          <Alert 
            type="error"
            title="Error | Maximum Selection Reached"
            text="You can select up to 20 accounts."
            confirmButtonText="Ok"
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }
    </div>
  );
}
