import { Item } from './item';

import './styles.css';

export const LeaderboardOverall = ({
  title,
  data,
  filterBy,
  filterByOptions = [], 
  onFilterByClick,
  maxScore,
  labels = [],
  isBigScores = false,
  sort,
  onSortClick,
  scoreBy,
  onScoreByClick,
  isByAccountDisabled,
  showFormattedScore,
  size,
  bottomContent,
  showPercentage = false,
}) => {
  return (
    <div className="results-leaderboard-overall-container">
      <div className={`leaderboard ${size}`}>
        <div className="leaderboard-header">
          <h2>{title}</h2>
        </div>
        <div className={`leaderboard-body ${!filterByOptions.length ? 'without-filter' : ''}`}>
          {
            sort && onSortClick &&
              <button className={`button-sort ${sort === 'asc' ? 'active' : ''}`} onClick={onSortClick}>Sort</button>
          }
          {
            scoreBy && onScoreByClick &&
              <div className="leaderboard-buttons leaderboard-body-buttons">
                <button className={scoreBy === 'account' ? 'active' : ''} onClick={() => onScoreByClick('account')} disabled={isByAccountDisabled}>By Account</button>
                <button className={scoreBy === 'player' ? 'active' : ''} onClick={() => onScoreByClick('player')}>By User</button>
              </div>
          }
          {
            labels.length > 0 &&
              <ul className="leaderboard-labels">
                {
                  labels.map((label, index) => (
                    <li key={index}><span className="label-color" style={{backgroundColor: label.color}}></span>{label.name}</li>
                  ))
                }
              </ul>
          }
          {
            filterByOptions.length > 0 &&
            <div className='leaderboard-filter-by'>
              <p>Filter By</p>
              <ul>
                {
                  filterByOptions.map((option, index) => (
                    <li key={index}><button className={filterBy === option ? 'active' : ''} onClick={() => onFilterByClick(option)}>{option}</button></li>
                  ))
                }
              </ul>
            </div>
          }
          <ul className={`leaderboard-data ${isBigScores ? 'big-scores' : ''}`}>
            {
              data && data.map((leaderboardItem, index) => (
                <Item
                  key={index + Date()}
                  name={leaderboardItem.name}
                  score={leaderboardItem.score}
                  formattedScore={leaderboardItem.formattedScore}
                  maxScore={maxScore}
                  showFormattedScore={showFormattedScore || filterBy === 'Accuracy'}
                  scoreIndicator={filterBy}
                  backgroundColor={leaderboardItem.backgroundColor}
                  lines={leaderboardItem.lines}
                  showPercentage={showPercentage}
                />
              ))
            }
          </ul>
        </div>

        {
          bottomContent &&
            <div className="leaderboard-footer">
              {bottomContent}
            </div>
        }
      </div>
    </div>
  );
};
