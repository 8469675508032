import { useState } from 'react';

import "react-datetime/css/react-datetime.css";
import './styles.css';

import InstructorLevelItemsModal from './levelItemsModal';
import Alert from '../../../alert';

import IconPlus from './images/icon-plus.svg';
import InstructorAssignmentLevelSavedAccounts from './levelSavedAccounts';

export default function InstructorAssignmentSetLevels({
  gameType,
  scenarioLevels,
  gamesItems,
  onScenarioLevelAccountSelectSave,
  isButtonLoading,
  onShowLevelAccounts,
  filterItems,
  onLevelDeletionConfirm,
  editingScenarioLevelIndex,
  editingScenarioLevelPreCheckedItems,
  isGameStarted
}) {
  const [activeTab, setActiveTab] = useState(0);
  
  const [isModalShown, setIsModalShown] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [alertType, setAlertType] = useState('warning');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertCancelButtonTest, setAlertCancelButtonTest] = useState('No');
  const [alertConfirmButtonTest, setAlertConfirmButtonTest] = useState('Yes');

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  }

  const showLevelAccounts = (scenarioLevelIndex) => {
    onShowLevelAccounts(scenarioLevelIndex);
    setIsModalShown(true);
  }

  const handleAddLevelButtonClick = () => {
    if(isGameStarted) {
      setAlertType('error');
      setAlertTitle('Unsuccessful');
      setAlertText('This assignment is locked for editing as students have already started working on it.');
      setAlertConfirmButtonTest('Ok');
      setAlertCancelButtonTest('');
      
      setIsAlertVisible(true);
    } else {
      showLevelAccounts();
    }
  }

  const handleEditLevelButtonClick = (scenarioLevelIndex) => {
    if(isGameStarted) {
      setAlertType('error');
      setAlertTitle('Unsuccessful');
      setAlertText('This assignment is locked for editing as students have already started working on it.');
      setAlertConfirmButtonTest('Ok');
      setAlertCancelButtonTest('');
      setIsAlertVisible(true);
    } else {
      showLevelAccounts(scenarioLevelIndex);
    }
  }

  const handleModalCloseButtonClick = () => {
    setIsModalShown(false);
  }

  const handleModalButtonSaveClick = () => {
    setIsModalShown(false);
  }

  const handleDeleteButtonClick = () => {
    if(isGameStarted) {
      setAlertType('error');
      setAlertTitle('Unsuccessful');
      setAlertText('This assignment is locked for editing as students have already started working on it.');
      setAlertConfirmButtonTest('Ok');
      setAlertCancelButtonTest('');
    } else {
      setAlertType('warning');
      setAlertTitle('');
      setAlertText('`Are you sure you want to delete this level?`');
      setAlertConfirmButtonTest('Yes');
      setAlertCancelButtonTest('No');
    }

    setIsAlertVisible(true);
  }

  const handleCancelButtonClick = () => {
    setIsAlertVisible(false);
  }

  const handleDeletionCompleted = () => {
    setActiveTab(0);
    setIsAlertVisible(false);
  }

  const handleConfirmAlertButtonClick = () => {
    if(isGameStarted) {
      setIsAlertVisible(false);
    } else {
      onLevelDeletionConfirm(scenarioLevels[activeTab]?.id, handleDeletionCompleted);
    }
  }

  return (
    <div className="instructor-set-levels-container">
      <h3>Set Levels</h3>
      <div className="instructor-assignment-levels">
        <div className="instructor-assignment-level-tabs">
          {
            scenarioLevels?.map((scenarioLevel, index) => {
              const isActive = index === activeTab;
              return(
                <div
                  key={index}
                  className={`assignment-tab-header ${isActive ? 'active' : ''}`} 
                  onClick={() => handleTabClick(index)}
                >
                  {
                    (isActive
                      && (!gameType?.includes('Account Classification') || (gameType?.includes('Account Classification') && index !== 0))
                      && (!gameType?.includes('Normal Balance') || (gameType?.includes('Normal Balance') && index !== 0))
                    ) &&
                      <button className="assignment-edit-scenario-level" onClick={() => handleEditLevelButtonClick(index)}></button>
                  }
                  <span>Level {scenarioLevel.level}</span>
                  {
                    (isActive 
                      && (index === scenarioLevels.length - 1) 
                      && (!gameType?.includes('Account Classification') || (gameType?.includes('Account Classification') && index !== 0))
                      && (!gameType?.includes('Normal Balance') || (gameType?.includes('Normal Balance') && index !== 0))
                    ) &&
                      <button className="assignment-delete-scenario-level" onClick={handleDeleteButtonClick}></button>
                  }
                </div>
              )
            })
          }
          {
            scenarioLevels?.length < 4 &&
              <button 
                className="assignment-tab-add-level"
                onClick={() => handleAddLevelButtonClick(null)}
              >
                Add Level <img src={IconPlus} alt="Add"/>
              </button>
          }
        </div>
        <div className="instructor-assignment-tab-content">
          {
            gamesItems && gameType && scenarioLevels && scenarioLevels.length > 0 &&
              <InstructorAssignmentLevelSavedAccounts
                gameType={gameType}
                scenarioLevels={scenarioLevels}
                gamesItems={gamesItems}
                activeScenarioLevel={activeTab}
              />
          }
        </div>
      </div>

      {isModalShown && (
        <InstructorLevelItemsModal
          scenarioLevelNumber={
            typeof editingScenarioLevelIndex === 'number' 
              ? (editingScenarioLevelIndex + 1) 
              : (scenarioLevels?.length + 1) || 0
          }
          onCloseButtonClick={handleModalCloseButtonClick}
          onSaveButtonClick={onScenarioLevelAccountSelectSave}
          onSave={handleModalButtonSaveClick}
          isButtonLoading={isButtonLoading}
          gameType={gameType}
          filterItems={filterItems}
          editingScenarioLevelPreCheckedItems={editingScenarioLevelPreCheckedItems}
          designTypes={scenarioLevels?.[editingScenarioLevelIndex]?.design}
        />
      )}

      {
        isAlertVisible &&
          <Alert 
            type={alertType}
            title={alertTitle}
            text={alertText}
            confirmButtonText={alertConfirmButtonTest}
            cancelButtonText={alertCancelButtonTest}
            onCancelButtonClick={handleCancelButtonClick}
            onConfirmButtonClick={handleConfirmAlertButtonClick}
          />
      }
    </div>
  );
}
