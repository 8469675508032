import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import reportWebVitals from './reportWebVitals';

import SoundStatusProvider from './context/SoundStatusContext';

import AuthenticatedRoute from './routes/AuthenticatedRoute';
import InstructorRoute from './routes/InstructorRoute';
import StudentRoute from './routes/StudentRoute';

import './index.css';
import Root from './components/Root';

import Classification from './components/Classification';
import ClassificationSorting from './components/Classification/sorting';
import ClassificationIntro from './components/Classification/intro';
import ClassificationSummary from './components/Classification/summary';
import ClassificationBadge from './components/Classification/badge';
import ClassificationAccuracy from './components/Classification/accuracy';
import ClassificationMostChallenging from './components/Classification/most-challenging';
import ClassificationFeedback from './components/Classification/feedback';
import ClassificationTutorial from './components/Classification/tutorial';
import ClassificationSortingTutorial from './components/Classification/sorting-tutorial';

import NormalBalanceSorting from './components/NormalBalance/sorting';
import NormalBalance from './components/NormalBalance';
import NormalBalanceIntro from './components/NormalBalance/intro';
import NormalBalanceSummary from './components/NormalBalance/summary';
import NormalBalanceAccuracy from './components/NormalBalance/accuracy';
import NormalBalanceMostChallenging from './components/NormalBalance/most-challenging';
import NormalBalanceTutorial from './components/NormalBalance/tutorial';
import NormalBalanceBadge from './components/NormalBalance/badge';
import NormalBalanceSortingTutorial from './components/NormalBalance/sorting-tutorial';

import ThePrinciples from './components/ThePrinciples';
import ThePrinciplesIntro from './components/ThePrinciples/intro';
import ThePrinciplesIntroMessages from './components/ThePrinciples/intro-messages';
import ThePrinciplesDebrief from './components/ThePrinciples/debrief';
import ThePrinciplesSummary from './components/ThePrinciples/summary';

import BalancedEquation from './components/BalancedEquation';
import BalancedEquationIntro from './components/BalancedEquation/intro';
import BalancedEquationTutorial from './components/BalancedEquation/tutorial';
import BalancedEquationBadge from './components/BalancedEquation/badge';

import Onboarding from './components/Onboarding';

import InstructorWelcomeToAccountingGames from './components/Instructor/welcome/welcomeToAccountingGames';
import InstructorWelcomeMasteringFundamentals from './components/Instructor/welcome/masteringFundamentals';
import InstructorWelcomeHowScoringWorks from './components/Instructor/welcome/howScoringWorks';
import InstructorWelcomeWaysToUse from './components/Instructor/welcome/waysToUse';
import InstructorWelcomeSettingUpForClass from './components/Instructor/welcome/settingUpForClass';

import InstructorSettingsAssignments from './components/Instructor/settings/assignments';
import InstructorSettingsAddAssignments from './components/Instructor/settings/assignments/add';
import InstructorSettingsEditAssignments from './components/Instructor/settings/assignments/edit';

import InstructorInsightsPoints from './components/Instructor/insights/leaderboard/points';
import InstructorInsightsAccuracy from './components/Instructor/insights/leaderboard/accuracy';
import InstructorInsightsAverageTime from './components/Instructor/insights/leaderboard/averageTime';

import InstructorInsightsOverall from './components/Instructor/insights/decisionInsights/overall';
import InstructorInsightsClassifyingNormal from './components/Instructor/insights/decisionInsights/classifying-normal';
import InstructorInsightsClassification from './components/Instructor/insights/decisionInsights/classification';
import InstructorInsightsNormal from './components/Instructor/insights/decisionInsights/normal';
import InstructorInsightsBalanced from './components/Instructor/insights/decisionInsights/balanced';
import InstructorInsightsDoubleEntry from './components/Instructor/insights/decisionInsights/double-entry';

import InstructorProgress from './components/Instructor/insights/progressScores/progress';

import Login from './components/Login';
import Transition from './components/Login/transition';
import NotFound from './components/NotFound';
import Games from './components/Games';
import LoginIntro from './components/Login/intro';

import Welcome from './components/Welcome';

import DecisionInsightsOverall from './components/Insights/decisionInsights/overall';
import DecisionInsightsClassifyingNormal from './components/Insights/decisionInsights/classifying-normal';
import DecisionInsightsDoubleEntry from './components/Insights/decisionInsights/double-entry';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [      
      {
        path: 'classification',
        children: [
          {
            path: `level/4`,
            element: <AuthenticatedRoute element={<ClassificationSorting levelNumber={4} />} />
          },
          {
            path: 'level/:levelNumber',
            element: <AuthenticatedRoute element={<Classification key={window.location.pathname} />} />
          },
          {
            path: 'level/4/tutorial',
            element: <AuthenticatedRoute element={<ClassificationSortingTutorial />} />
          },
          {
            path: 'level/:levelNumber/tutorial',
            element: <AuthenticatedRoute element={<ClassificationTutorial />} />
          },
          {
            path: 'level/:levelNumber/summary',
            element: <AuthenticatedRoute element={<ClassificationSummary />} />
          },
          {
            path: 'level/:levelNumber/accuracy',
            element: <AuthenticatedRoute element={<ClassificationAccuracy />} />
          },
          {
            path: 'level/:levelNumber/most-challenging',
            element: <AuthenticatedRoute element={<ClassificationMostChallenging />} />
          },
          {
            path: 'badge',
            element: <AuthenticatedRoute element={<ClassificationBadge />} />
          },
          {
            path: 'feedback',
            element: <AuthenticatedRoute element={<ClassificationFeedback />} />
          },
          {
            path: 'intro',
            element: <AuthenticatedRoute element={<ClassificationIntro />} />
          },
        ]
      },
      {
        path: 'normal',
        children: [
          {
            path: `level/5`,
            element: <AuthenticatedRoute element={<NormalBalanceSorting levelNumber={5} />} />
          },
          {
            path: 'level/:levelNumber',
            element: <AuthenticatedRoute element={<NormalBalance key={window.location.pathname} />} />
          },
          {
            path: 'level/5/tutorial',
            element: <AuthenticatedRoute element={<NormalBalanceSortingTutorial />} />
          },
          {
            path: 'level/:levelNumber/tutorial',
            element: <AuthenticatedRoute element={<NormalBalanceTutorial />} />
          },
          {
            path: 'level/:levelNumber/intro',
            element: <AuthenticatedRoute element={<NormalBalanceIntro />} />
          },
          {
            path: 'level/:levelNumber/summary',
            element: <AuthenticatedRoute element={<NormalBalanceSummary />} />
          },
          {
            path: 'level/:levelNumber/accuracy',
            element: <AuthenticatedRoute element={<NormalBalanceAccuracy />} />
          },
          {
            path: 'level/:levelNumber/most-challenging',
            element: <AuthenticatedRoute element={<NormalBalanceMostChallenging />} />
          },
          {
            path: 'badge',
            element: <AuthenticatedRoute element={<NormalBalanceBadge />} />
          },
        ]
      },
      {
        path: '/the-principles',
        children: [
          {
            path: 'intro',
            element: <AuthenticatedRoute element={<ThePrinciplesIntro />} />
          },
          {
            path: 'intro-messages',
            element: <AuthenticatedRoute element={<ThePrinciplesIntroMessages />} />
          },
          {
            path: 'level/:levelNumber',
            element: <AuthenticatedRoute element={<ThePrinciples />} />
          },
          {
            path: 'debrief',
            element: <AuthenticatedRoute element={<ThePrinciplesDebrief />} />
          },
          {
            path: 'summary',
            element: <AuthenticatedRoute element={<ThePrinciplesSummary />} />
          },
        ]
      },
      {
        path: '/balanced-equation',
        children: [
          {
            path: 'level/:levelNumber/tutorial',
            element: <AuthenticatedRoute element={<BalancedEquationTutorial />} />
          },
          {
            path: 'level/:levelNumber/intro',
            element: <AuthenticatedRoute element={<BalancedEquationIntro />} />
          },
          {
            path: 'level/:levelNumber',
            element: <AuthenticatedRoute element={<BalancedEquation />} />
          },
          {
            path: 'debits-credits/intro',
            element: <AuthenticatedRoute element={<BalancedEquationIntro />} />
          },
          {
            path: 'badge',
            element: <AuthenticatedRoute element={<BalancedEquationBadge />} />
          },
        ]
      },
      {
        path: 'onboarding',
        element: <StudentRoute element={<Onboarding/>} />
      },
      {
        path: 'welcome',
        element: <StudentRoute element={<Welcome/>} />
      },
      {
        path: 'instructor',
        children: [
          {
            path: 'dashboard',
            element: <InstructorRoute element={<InstructorWelcomeToAccountingGames/>} />
          },
          {
            path: 'welcome',
            children: [
              {
                path: 'welcome-to-accounting-games',
                element: <InstructorRoute element={<InstructorWelcomeToAccountingGames/>} />
              },
              {
                path: 'mastering-fundamentals',
                element: <InstructorRoute element={<InstructorWelcomeMasteringFundamentals/>} />
              },
              {
                path: 'how-scoring-works',
                element: <InstructorRoute element={<InstructorWelcomeHowScoringWorks/>} />
              },
              {
                path: 'ways-to-use',
                element: <InstructorRoute element={<InstructorWelcomeWaysToUse/>} />
              },
              {
                path: 'setting-up-for-class',
                element: <InstructorRoute element={<InstructorWelcomeSettingUpForClass/>} />
              },
            ]
          },
          {
            path: 'settings',
            children: [
              {
                path: 'assignments',
                children: [
                  {
                    path: '',
                    element: <InstructorRoute element={<InstructorSettingsAssignments />} />
                  },
                  {
                    path: 'add',
                    element: <InstructorRoute element={<InstructorSettingsAddAssignments />} />
                  },
                  {
                    path: 'edit/:gameId',
                    element: <InstructorRoute element={<InstructorSettingsEditAssignments />} />
                  }
                ]
              }
            ]
          },
          {
            path: 'insights',
            children: [
              {
                path: '',
                element: <InstructorRoute element={<InstructorInsightsPoints />} />
              },
              {
                path: 'leaderboard',
                children: [
                  {
                    path: 'points',
                    element: <InstructorRoute element={<InstructorInsightsPoints />} /> 
                  },
                  {
                    path: 'accuracy',
                    element: <InstructorRoute element={<InstructorInsightsAccuracy />} /> 
                  },
                  {
                    path: 'average-time',
                    element: <InstructorRoute element={<InstructorInsightsAverageTime />} /> 
                  }
                ]
              },
              {
                path: 'decision-insights',
                children: [
                  {
                    path: 'overall',
                    element: <InstructorRoute element={<InstructorInsightsOverall />} /> 
                  },
                  {
                    path: 'classifying-normal',
                    element: <InstructorRoute element={<InstructorInsightsClassifyingNormal />} /> 
                  },
                  {
                    path: 'double-entry',
                    element: <InstructorRoute element={<InstructorInsightsDoubleEntry />} /> 
                  },
                  {
                    path: 'classification/:levelNumber',
                    element: <InstructorRoute element={<InstructorInsightsClassification />} /> 
                  },
                  {
                    path: 'normal/:levelNumber',
                    element: <InstructorRoute element={<InstructorInsightsNormal />} /> 
                  },
                  {
                    path: 'balanced/:levelNumber',
                    element: <InstructorRoute element={<InstructorInsightsBalanced />} /> 
                  }
                ],
              },
              {
                path: 'progress-scores',
                children: [
                  {
                    path: 'progress',
                    element: <InstructorRoute element={<InstructorProgress />} /> 
                  }
                ],
              }
            ]
          }
        ]
      },
      {
        path: '/acc',
        children: [
          {
            path: 'login',
            element: <Login />
          },
          {
            path: 'welcome',
            element: <LoginIntro />
          },
          {
            path: 'transition',
            element: <AuthenticatedRoute element={<Transition/>} />
          }
        ]
      },
      {
        path: 'assignments',
        element: <AuthenticatedRoute element={<Games />} />
      },
      {
        path: 'insights',
        children: [
          {
            path: '',
            element: <AuthenticatedRoute element={<DecisionInsightsOverall />} />
          },
          {
            path: 'decision-insights',
            children: [
              {
                path: 'overall',
                element: <AuthenticatedRoute element={<DecisionInsightsOverall />} /> 
              },
              {
                path: 'classifying-normal',
                element: <AuthenticatedRoute element={<DecisionInsightsClassifyingNormal />} /> 
              },
              {
                path: 'double-entry',
                element: <AuthenticatedRoute element={<DecisionInsightsDoubleEntry />} /> 
              },
            ],
          }
        ]
      },
      {
        path: '*',
        element: <AuthenticatedRoute element={<NotFound />} />
      }
    ]
  }
], {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
        <SoundStatusProvider>
          <RouterProvider router={router} future={{ v7_startTransition: true }} />
        </SoundStatusProvider>
      </QueryClientProvider>
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
