import { useState, useEffect } from 'react';

import './styles.css';

export const ItemScore = ({ 
  id,
  score,
  maxScore,
  formattedScore,
  scoreIndicator,
  showFormattedScore,
  backgroundColor,
  showPercentage
}) => {
  const [animatedWidth, setAnimatedWidth] = useState('0%');
  const [animatedScore, setAnimatedScore] = useState('0');
  const animationSpeed = 25; // pixels per second

  useEffect(() => {
    if (isNaN(score)) {
      setAnimatedWidth('0%');
      setAnimatedScore('tbd');
      return;
    }
    
    const targetWidthPercentage = (score / (maxScore || 1) * 100);
    const duration = (targetWidthPercentage / animationSpeed) * 1000;
    const formattedScoreString = String(formattedScore);
    const endScore = parseFloat(formattedScoreString.replace('%', ''));

    let elapsed = 0;

    const interval = setInterval(() => {
      elapsed += 10;
      if (elapsed >= duration) {
        clearInterval(interval);
        setAnimatedWidth(`${targetWidthPercentage.toFixed(2)}%`);
        setAnimatedScore(showFormattedScore ? formattedScore : Math.round(endScore));
      } else {
        const progress = elapsed / duration;
        setAnimatedWidth(`${(targetWidthPercentage * progress).toFixed(2)}%`);
        setAnimatedScore(
          showFormattedScore 
            ? scoreIndicator === 'Accuracy' || showPercentage
              ? `${(endScore * progress).toFixed(2)}%` 
              : `${(endScore * progress).toFixed(2)}` 
            : Math.round(endScore * progress)
        );
      }
    }, 10);

    return () => clearInterval(interval);
  }, [id, score, maxScore, formattedScore, scoreIndicator, showFormattedScore, showPercentage]);

  return (
    <span
      className={`user-score ${(scoreIndicator === 'Accuracy' || showPercentage) ? 'accuracy-user-score' : ''}`}
      style={{ width: animatedWidth, backgroundColor: backgroundColor }}
    >
      <span>{animatedScore}</span>
    </span>
  );
};
